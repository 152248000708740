<template>
  <div v-if="errMsg">{{ errMsg }}</div>
  <div v-if="xhsContent" class="wrapper">
    <div class="title">
      <div>标题</div>
      <div class="copy" @click="copyToClipboard(xhsContent.title)">
        点击复制
      </div>
    </div>
    <div class="content">{{ xhsContent.title }}</div>
    <div class="title">
      <div>正文</div>
      <div class="copy" @click="copyToClipboard(xhsContent.content)">
        点击复制
      </div>
    </div>
    <div class="content">{{ xhsContent.content }}</div>
    <div class="title">
      <div>标签</div>
      <div class="copy" @click="copyToClipboard(xhsContent.tags)">点击复制</div>
    </div>
    <div class="content">{{ xhsContent.tags }}</div>
    <div class="title">
      <div>图片</div>
      <div class="tips">😊 请按顺序上传</div>
    </div>
    <div class="pic-wrapper">
      <div class="pic" :key="index" v-for="(pic, index) in xhsContent.pics">
        <img :src="pic.url" />
        <div class="num">{{ index + 1 }} / {{ xhsContent.pics.length }}</div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref } from "vue";
import axios from "axios";
import { copyToClipboard, getQueryParam } from "@/utils";

const errMsg = ref("");
const xhsContent = ref<XhsContentFromServer | undefined>(undefined);

async function getData(key: string): Promise<XhsContentFromServer> {
  const res: { data: ApiRes<XhsContentFromServer> } = await axios.get(
    `/get-xhs-data?key=${key}`
  );

  if (res.data.ec === 0) {
    return res.data.data as XhsContentFromServer;
  }
  throw new Error(res.data.em);
}

onMounted(async () => {
  const key = getQueryParam("key");
  if (!key) {
    errMsg.value = "参数错误";
    return;
  }
  try {
    const data = await getData(key);
    xhsContent.value = data;
  } catch (e) {
    errMsg.value = "链接已失效";
  }
});
</script>

<style scoped>
.wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: bold;
  margin: 20px 0 10px;
}

.copy {
  font-size: 14px;
  color: #42b983;
}
.tips {
  font-size: 14px;
  opacity: 0.6;
}

.content {
  font-size: 12px;
  padding: 10px;
  box-sizing: border-box;
  background: #f5f5f5;
  border: 1px solid #dddddd;
  border-radius: 10px;
}

.pic {
  width: 50%;
  margin: 2%;
  padding: 10px;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pic img {
  width: 100%;
  margin-bottom: 10px;
}
.pic .num {
  font-size: 12px;
  font-weight: bold;
}
</style>

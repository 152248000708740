<script setup lang="ts">
import { onMounted, ref } from "vue";
import axios from "axios";
import { getQueryParam } from "@/utils";

const content = ref("");

onMounted(async () => {
  const picSourceId = getQueryParam("picSourceId");
  if (!picSourceId) {
    return;
  }
  const res = await axios.get(`/get-pic-source?picSourceId=${picSourceId}`);
  if (res.data.ec === 0) {
    content.value = res.data.data;
  }
});
</script>

<template>
  <div class="wrapper">
    <div class="p-bg-normal apple-top"></div>
    <div class="content" v-html="content"></div>
    <div class="p-bg-normal apple-bottom"></div>
  </div>
</template>

<style scoped lang="postcss">
.wrapper {
  .apple-top {
    width: 375px;
    height: 80px;
    position: fixed;
    top: 0;
    background-image: url("@/assets/appleMemo/apple_memo_top.png");
  }
  .apple-bottom {
    position: fixed;
    bottom: 0;
    width: 375px;
    height: 69px;
    background-image: url("@/assets/appleMemo/apple_memo_bottom.png");
  }
  .content {
    padding: 80px 10px 129px;
    white-space: break-spaces;
    line-height: 30px;
  }
}
</style>

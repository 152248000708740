import { createRouter, createWebHashHistory } from "vue-router";

import appleMemo from "@/components/template/AppleMemo.vue";
import appleMemoNormal from "@/components/template/AppleMemoNormal.vue";
import canvaNote1 from "@/components/template/CanvaNote1.vue";
import canvaCover1 from "@/components/template/CanvaCover1.vue";
import simpleWhite from "@/components/template/SimpleWhite.vue";
import preview from "@/components/PreviewPage.vue";

const routes = [
  { path: "/", component: preview },
  { path: "/preview", component: preview },
  { path: "/appleMemo", component: appleMemo },
  { path: "/canvaNote1", component: canvaNote1 },
  { path: "/canvaCover1", component: canvaCover1 },
  { path: "/simpleWhite", component: simpleWhite },
  { path: "/appleMemoNormal", component: appleMemoNormal },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;

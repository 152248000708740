<script setup lang="ts">
import { onMounted, ref } from "vue";
import axios from "axios";
import { getQueryParam } from "@/utils";

const content = ref<PicSource4CanvaCover1>({
  emoji: "",
  line1: "",
  line2: "",
  line3: "",
});

onMounted(async () => {
  const picSourceId = getQueryParam("picSourceId");
  if (!picSourceId) {
    return;
  }
  const res = await axios.get(`/get-pic-source?picSourceId=${picSourceId}`);
  if (res.data.ec === 0) {
    content.value = res.data.data;
  }
});
</script>

<template>
  <div class="wrapper">
    <div class="content">
      <div class="line1">{{ content.line1 }}</div>
      <div class="line2">{{ content.line2 }}</div>
      <div class="emoji">{{ content.emoji }}</div>
      <div class="line3">{{ content.line3 }}</div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b60404;
  .content {
    width: 355px;
    height: 500px;
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
    font-family: "IdeaFonts QiMiaoTi", "PingFang SC", "Helvetica Neue", Arial,
      sans-serif;
    background: #ffffff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .line1 {
      text-align: center;
      font-size: 32px;
      width: 335px;
      overflow: hidden;
      font-family: "IdeaFonts PiNuoCao", "PingFang SC", "Helvetica Neue", Arial,
        sans-serif;
      //word-break: keep-all;
      margin-bottom: 30px;
    }
    .line2 {
      text-align: center;
      font-size: 36px;
      width: 335px;
      overflow: hidden;
      word-break: keep-all;
      margin-bottom: 20px;
      color: #b60404;
    }
    .emoji {
      font-size: 96px;
      font-family: "Apple Color Emoji";
      margin-bottom: 20px;
    }
    .line3 {
      font-size: 16px;
    }
  }
}
</style>

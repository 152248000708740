<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import axios from "axios";
import { getQueryParam } from "@/utils";
import dayjs from "dayjs";

const data = ref<PicSource4AppleMemoNormal>({
  topStr: "",
  title: "",
  text: "",
  resolution: "3:4",
  showTime: false,
  showFoot: false,
  useSmallTitle: false,
});
const scale = computed(() => false);
const lineCount = ref(1);
const titleHeight = computed(() => Math.ceil((lineCount.value * 36) / 26) * 26);
function countCharacters(text: string): number {
  let count = 0;

  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i);

    // 使用正则表达式判断是否为中文或全角标点符号
    if (/[\u4E00-\u9FA5\uFF00-\uFFEF]/.test(char)) {
      count += 2;
    } else {
      count += 1;
    }
  }

  return count;
}

onMounted(async () => {
  const picSourceId = getQueryParam("picSourceId");
  if (!picSourceId) {
    return;
  }
  const res = await axios.get(`/get-pic-source?picSourceId=${picSourceId}`);
  if (res.data.ec === 0) {
    data.value = res.data.data as PicSource4AppleMemoNormal;
    lineCount.value = Math.ceil(countCharacters(data.value.title) / 20);
  }
});
</script>

<template>
  <div class="wrapper" :class="[scale ? 'scale' : '']">
    <div class="p-bg-normal apple-top">
      <div v-if="data.topStr" class="top-str">{{ data.topStr }}</div>
    </div>
    <div class="content-wrapper">
      <div v-if="data.showTime" class="time">
        {{ dayjs(Date.now()).format("YYYY年MM月DD日 HH:mm") }}
      </div>
      <div v-if="data.useSmallTitle" class="title-small">{{ data.title }}</div>
      <div v-else class="title" :style="{ height: `${titleHeight}px` }">
        {{ data.title }}
      </div>
      <div class="content" v-html="data.text"></div>
    </div>
    <div v-if="data.showFoot" class="p-bg-normal apple-bottom"></div>
  </div>
</template>

<style scoped lang="postcss">
.wrapper {
  &.scale {
    transform: scale(0.74);
    transform-origin: top;
  }
  .apple-top {
    .top-str {
      position: absolute;
      top: 30px;
      left: 30px;
      color: #e4af0a;
      font-size: 16px;
    }

    width: 375px;
    height: 64px;
    position: fixed;
    top: 0;
    background-image: url("@/assets/appleMemo/apple_memo_top.png");
  }

  .apple-bottom {
    position: fixed;
    bottom: 0;
    width: 375px;
    height: 69px;
    background-image: url("@/assets/appleMemo/apple_memo_bottom.png");
  }

  .content-wrapper {
    padding: 64px 24px 129px;
    font-size: 17px;
    letter-spacing: 1.5px;
    line-height: 26px;
    color: #454545;
    vertical-align: middle;

    .title {
      font-size: 28px;
      line-height: 36px;
      font-weight: bold;
      letter-spacing: 1.5px;
    }
    .title-small {
      font-size: 17px;
      letter-spacing: 1.5px;
      line-height: 26px;
      font-weight: bold;
    }

    .time {
      color: #8b8a8e;
      font-size: 15px;
      line-height: 26px;
      letter-spacing: 1px;
      text-align: center;
    }

    .content {
      letter-spacing: -0.5px;
      white-space: break-spaces;
    }
  }
}
</style>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import axios from "axios";
import { getQueryParam } from "@/utils";

const content = ref<PicSource4CanvaNote1>({
  title: "",
  text: "",
});

onMounted(async () => {
  const picSourceId = getQueryParam("picSourceId");
  if (!picSourceId) {
    return;
  }
  const res = await axios.get(`/get-pic-source?picSourceId=${picSourceId}`);
  if (res.data.ec === 0) {
    content.value = res.data.data;
  }
});
</script>

<template>
  <div class="wrapper">
    <div class="title">
      <div>{{ content.title }}</div>
      <div class="line"></div>
    </div>
    <div class="content" v-html="content.text"></div>
  </div>
</template>

<style scoped lang="postcss">
.wrapper {
  .title {
    padding: 0 10px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: 375px;
    height: 67px;
    font-size: 26px;
    line-height: 45px;
    font-family: "IdeaFonts QiMiaoTi", serif;
    background: #ffffff;
  }
  .line {
    width: 200px;
    height: 5px;
    background-color: #b60404;
    margin-bottom: 10px;
  }
  .content {
    padding: 67px 10px 180px;
    white-space: break-spaces;
    line-height: 20px;
    font-size: 14px;
    font-family: "IdeaFonts PiNuoCao", "Apple Color Emoji", "PingFang SC",
      "Helvetica Neue", Arial, sans-serif;
    //font-family: "SYYJAHT", serif;
  }
}
</style>

import { createApp } from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "@/router";

export const baseUrl =
  location.href.indexOf("localhost") > 0
    ? "http://localhost:3000/rb/"
    : "https://redflow-io.poclas.com/rb/";

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
const app = createApp(App);
app.use(router);
app.mount("#app");

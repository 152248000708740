<template>
  <router-view />
</template>
<script lang="ts"></script>
<style lang="postcss">
body {
  margin: 0;
  font-family: "PingFang SC", "Apple Color Emoji", "Helvetica Neue", Arial,
    sans-serif;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.p-wrapper {
  width: 100%;
}
.p-bg-normal {
  background-size: cover;
  background-repeat: no-repeat;
}
mark {
  margin: 0 -0.4em;
  padding: 0 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  background-image: linear-gradient(
    to right,
    rgba(255, 225, 0, 0.1),
    rgba(255, 225, 0, 0.7) 4%,
    rgba(255, 225, 0, 0.3)
  );
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
</style>
